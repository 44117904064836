import * as AuditRequests from './AuditRequests';
import * as AuditList from './AuditList';


// The top-level state object
export interface ApplicationState {
    siteId: number;
    auditRequests: AuditRequests.IAuditRequestState | undefined;
    auditList: AuditList.IAuditListState | undefined;
}

export const reducers = {
    auditRequests: AuditRequests.reducer,
    auditList: AuditList.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
