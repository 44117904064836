import * as React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../images/logo.png';
import { UserLogin, IUserAuthentication } from './UserLogin';

export interface INavProps {
    collapsed: boolean
    userAuth: IUserAuthentication
}

export default class NavMenu extends React.Component {
     render() { 
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img alt="Pharmascience" src={logo}/> | Audit Tracking</NavbarBrand>
                        <UserLogin {...this.props}/>
                    </Container>
                </Navbar>
            </header>
        );
    };
}
