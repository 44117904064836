import * as React from 'react';

export interface IAuditor {
  fullName: string,
  email: string
}

export interface IUserContext {
  fullName: string,
  email: string,
  role: string,
  userSite: string,
  selectedSiteId: number,
  auditorsCandiac: IAuditor[],
  auditorsRoyalmount: IAuditor[],
}

const userContext = React.createContext({fullName: '', email: '', userSite: '', selectedSiteId: -1}); // Create a context object

export {
  userContext // Export it so it can be used by other Components
};