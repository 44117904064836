import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { AuditDocumentType, IAudit } from '../models/Audit';
import { AuditRequestMailService } from '../services/AuditRequestMailService';
import { TelemetryService } from '../services/TelemetryService';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAuditRequestState {
    isLoading: boolean;
    auditId?: number;
    auditRequests: IAuditRequest[];
    audit: IAudit;
    message: string;
    messageSeverity: Severity;
    isOpen: boolean;
    externalAuditors: string[];
    downloadUrl: string;
    downloadFilename: string;
}

export interface IAuditRequest {
    id: number
    auditId: number;
    requestNumber: number;
    creationDate: string;
    modifiedTimestamp: string;
    createdBy: string;
    modifiedBy: string;
    documentRequested: string;
    sme1: string;
    sme2: string;
    department: string;
    smeNotified: boolean;
    warRoomRecd: boolean;
    warRoomVerified: boolean;
    inAuditRoom: boolean;
    subjectWithAuditor: boolean;
    documentWithAuditor: boolean;
    comments: string;
    commentsBy: string;
    commentsByTimestamp: string;
    urgent: boolean;
    subjectClosed: boolean;
}

type Severity = "error" | "success" | "info" | "warning" | undefined;

interface RequestAuditRequestAction {
    type: 'REQUEST_AUDIT_REQUESTS';
    auditId: number;
}

interface ReceiveAuditRequestAction {
    type: 'RECEIVE_AUDIT_REQUESTS';
    auditId: number;
    auditRequests: IAuditRequest[];
}

interface RequestAuditAction {
    type: 'REQUEST_AUDIT';
    auditId: number;
}

interface ReceiveAuditAction {
    type: 'RECEIVE_AUDIT';
    auditId: number;
    audit: IAudit;
}

interface RequestAddRequestAction {
    type: 'REQUEST_ADD_REQUEST';
}

interface ReceiveAddRequestAction {
    type: 'RECEIVE_ADD_REQUEST';
    addedRequest: IAuditRequest;
}

interface RequestUpdateRequestAction {
    type: 'REQUEST_UPDATE_REQUEST';
}

interface ReceiveUpdateRequestAction {
    type: 'RECEIVE_UPDATE_REQUEST';
    updatedRequest: IAuditRequest;
}

interface RequestDeleteRequestAction {
    type: 'REQUEST_DELETE_REQUEST';
}

interface ReceiveDeleteRequestAction {
    type: 'RECEIVE_DELETE_REQUEST';
    requestId: number;
}

interface RequestUpdateAuditAction {
    type: 'REQUEST_UPDATE_AUDIT';
}

interface ReceiveUpdateAuditAction {
    type: 'RECEIVE_UPDATE_AUDIT';
    updatedAudit: IAudit;
}

interface ShowMessageAction {
    type: 'SHOW_MESSAGE';
    message: string;
    messageSeverity: Severity;
    isLoading: boolean;
}

interface HideMessageAcion {
    type: 'HIDE_MESSAGE';
}

interface RequestExternalAuditors {
    type: 'REQUEST_EXTERNAL_AUDITORS'
}

interface ReceiveExternalAuditors {
    type: 'RECEIVE_EXTERNAL_AUDITORS'
    externalAuditors: string[];
}

interface RequestExportAuditAction {
    type: 'REQUEST_EXPORT_AUDIT'
}

interface ReceiveExportAuditAction {
    type: 'RECEIVE_EXPORT_AUDIT'
    downloadUrl: string
    downloadFilename: string
}

type KnownAction = RequestAuditRequestAction |
    ReceiveAuditRequestAction |
    RequestAuditAction |
    ReceiveAuditAction |
    ReceiveAddRequestAction |
    ReceiveUpdateRequestAction |
    ReceiveUpdateAuditAction |
    RequestAddRequestAction |
    RequestUpdateRequestAction |
    RequestUpdateAuditAction |
    RequestDeleteRequestAction |
    ReceiveDeleteRequestAction |
    ShowMessageAction |
    HideMessageAcion |
    RequestExternalAuditors |
    ReceiveExternalAuditors |
    RequestExportAuditAction |
    ReceiveExportAuditAction;

export const actionCreators = {
    requestAuditRequests: (auditId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(`auditrequest/${auditId}`)
            .then(response => {
                const data = response.data as IAuditRequest[]

                if (data && data.length > 0) {
                    data.forEach((auditRequest) => {
                        // Specify date as UTC with Z at the end
                        auditRequest.creationDate = auditRequest.creationDate + 'Z';
                        auditRequest.modifiedTimestamp = auditRequest.modifiedTimestamp + 'Z';
                    });
                }
                dispatch({ type: 'RECEIVE_AUDIT_REQUESTS', auditId: auditId, auditRequests: data });
            }).catch(error => {
                TelemetryService.trackException(error);
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Error on getting Audit Requests',
                    messageSeverity: 'error',
                    isLoading: false
                });
                dispatch({ type: 'RECEIVE_AUDIT_REQUESTS', auditId: auditId, auditRequests: unloadedState.auditRequests });
            });

        dispatch({ type: 'REQUEST_AUDIT_REQUESTS', auditId: auditId });
    },
    requestAudit: (auditId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            axios.get(`audit/${auditId}`)
                .then(response => {
                    const data = response.data as IAudit
                    dispatch({
                        type: 'RECEIVE_AUDIT',
                        auditId: data.id, audit: {
                            id: data.id,
                            siteId: data.siteId,
                            creationDate: data.creationDate,
                            createdBy: data.createdBy || '',
                            modifiedTimestamp: data.modifiedTimestamp,
                            modifiedBy: data.modifiedBy || '',
                            description: data.description || '',
                            numberOfRequests: data.numberOfRequests,
                            auditDateStart: data.auditDateStart ? data.auditDateStart.split('T')[0] : '',
                            auditDateEnd: data.auditDateEnd ? data.auditDateEnd.split('T')[0] : '',
                            auditor1: data.auditor1 || '',
                            auditor2: data.auditor2 || '',
                            auditor3: data.auditor3 || '',
                            auditLead: data.auditLead || '',
                            auditLeadEmail: data.auditLeadEmail || '',
                            auditSupport1: data.auditSupport1 || '',
                            auditSupport1Email: data.auditSupport1Email || '',
                            auditSupport2: data.auditSupport2 || '',
                            auditSupport2Email: data.auditSupport2Email || '',
                            auditSupport3: data.auditSupport2 || '',
                            auditSupport3Email: data.auditSupport2Email || '',
                            auditWarRoom1: data.auditWarRoom1 || '',
                            auditWarRoom1Email: data.auditWarRoom1Email || '',
                            auditWarRoom2: data.auditWarRoom2 || '',
                            auditWarRoom2Email: data.auditWarRoom2Email || '',
                            auditWarRoom3: data.auditWarRoom3 || '',
                            auditWarRoom3Email: data.auditWarRoom3Email || '',
                            auditWarRoom4: data.auditWarRoom4 || '',
                            auditWarRoom4Email: data.auditWarRoom4Email || '',
                            archived: data.archived || false,
                        }
                    });
                }).catch(error => {
                    TelemetryService.trackException(error);
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        message: 'Error on getting Audit',
                        messageSeverity: 'error',
                        isLoading: false
                    });
                    dispatch({
                        type: 'RECEIVE_AUDIT',
                        auditId: auditId,
                        audit: unloadedState.audit
                    });
                });

            dispatch({ type: 'REQUEST_AUDIT', auditId: auditId });
        }
    },
    addRequest: (newRequest: IAuditRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            axios.post(`auditrequest`, newRequest)
                .then(response => {
                    const data = response.data as IAuditRequest;
                    dispatch({ type: 'RECEIVE_ADD_REQUEST', addedRequest: data });
                    AuditRequestMailService.sendMail(data);
                }).catch(error => {
                    TelemetryService.trackException(error);
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        message: 'Error on adding new Audit',
                        messageSeverity: 'error',
                        isLoading: false
                    });
                });

            dispatch({ type: 'REQUEST_ADD_REQUEST' });
        }

    },
    sendMail: (auditRequest: IAuditRequest): void => {
        AuditRequestMailService.sendMail(auditRequest);
    },
    updateRequest: (updatedReqest: IAuditRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let payload = {
                ...updatedReqest,
                commentsByTimestamp: updatedReqest.commentsByTimestamp ? updatedReqest.commentsByTimestamp : undefined
            }

            axios.put(`auditrequest/${updatedReqest.id}`, payload).then(() => {
                dispatch({ type: 'RECEIVE_UPDATE_REQUEST', updatedRequest: updatedReqest });
            }).catch(error => {
                TelemetryService.trackException(error);
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Error on updating request',
                    messageSeverity: 'error',
                    isLoading: false
                });
            });;

            dispatch({ type: 'REQUEST_UPDATE_REQUEST' });
        }
    },
    deleteRequest: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            axios.delete(`auditrequest/${id}`)
                .then(() => {
                    dispatch({ type: 'RECEIVE_DELETE_REQUEST', requestId: id });
                }).catch(error => {
                    TelemetryService.trackException(error);
                    dispatch({ type: 'RECEIVE_DELETE_REQUEST', requestId: 0 });
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        message: 'Error on updating request',
                        messageSeverity: 'error',
                        isLoading: false
                    });
                });

            dispatch({ type: 'REQUEST_UPDATE_REQUEST' });
        }
    },
    updateAudit: (updatedAudit: IAudit): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let payload = {
                ...updatedAudit,
                //creationDate: updatedAudit.creationDate ? updatedAudit.creationDate : undefined,
                auditDateStart: updatedAudit.auditDateStart ? updatedAudit.auditDateStart + "T00:00:00" : undefined,
                auditDateEnd: updatedAudit.auditDateEnd ? updatedAudit.auditDateEnd + "T00:00:00" : undefined,
            }
            let body = JSON.stringify(payload);

            axios.put(`audit/${updatedAudit.id}`, body)
                .then(() => {
                    dispatch({ type: 'RECEIVE_UPDATE_AUDIT', updatedAudit: updatedAudit });
                }).catch(error => {
                    TelemetryService.trackException(error);
                    dispatch({ type: 'RECEIVE_DELETE_REQUEST', requestId: 0 });
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        message: 'Error on updating request',
                        messageSeverity: 'error',
                        isLoading: false
                    });

                });

            dispatch({ type: 'REQUEST_UPDATE_AUDIT' });
        }
    },
    showMessage: (message: string, messageSeverity: Severity): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && message) {
            dispatch({
                type: 'SHOW_MESSAGE',
                message: message,
                messageSeverity: messageSeverity,
                isLoading: false
            });
        }
    },
    hideMessage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'HIDE_MESSAGE' });
        }
    },
    getExternalAuditors: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            axios.get(`externalauditor/`)
                .then(response => {
                    const data = response.data as string[]
                    dispatch({ type: 'RECEIVE_EXTERNAL_AUDITORS', externalAuditors: data });
                }).catch(error => {
                    TelemetryService.trackException(error);
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        message: 'Error on getting Audit Requests',
                        messageSeverity: 'error',
                        isLoading: false
                    });
                    dispatch({ type: 'RECEIVE_EXTERNAL_AUDITORS', externalAuditors: [] });
                });

            dispatch({ type: 'REQUEST_EXTERNAL_AUDITORS', });
        }
    },
    export: (auditId: number, auditDocumentType: AuditDocumentType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {

            let url: string;
            let contentType: string;
            let fileExtension: string;

            switch (auditDocumentType) {
                case AuditDocumentType.Pdf:
                    url = `audit/${auditId}/pdf`;
                    contentType = 'application/pdf';
                    fileExtension = 'pdf';
                    break;
                case AuditDocumentType.Spreadsheet:
                    url = `audit/${auditId}/spreadsheet`;
                    contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    fileExtension = 'xlsx';
                    break;
                default:
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        message: 'Error occurred while exporting document.',
                        messageSeverity: 'error',
                        isLoading: false
                    });
                    return;
            }

            axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Content-Type': contentType
                }
            })
            .then(response => {
                let downloadUrl = URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                let filename = `{${auditId}}.${fileExtension}`;

                var contentDisposition = response.headers['content-disposition'];
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                dispatch({
                    type: 'RECEIVE_EXPORT_AUDIT',
                    downloadUrl: downloadUrl,
                    downloadFilename: filename
                });
                
            }).catch(error => {
                TelemetryService.trackException(error);
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Error occurred while exporting document.',
                    messageSeverity: 'error',
                    isLoading: false
                });
            });

            dispatch({ type: 'REQUEST_EXPORT_AUDIT' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IAuditRequestState = {
    auditRequests: [],
    isLoading: false,
    audit: {
        id: 0,
        siteId: 0,
        creationDate: '',
        createdBy: '',
        modifiedTimestamp: '',
        modifiedBy: '',
        description: '',
        numberOfRequests: 0,
        auditDateStart: '',
        auditDateEnd: '',
        auditor1: '',
        auditor2: '',
        auditor3: '',
        auditLead: '',
        auditLeadEmail: '',
        auditSupport1: '',
        auditSupport1Email: '',
        auditSupport2: '',
        auditSupport2Email: '',
        auditSupport3: '',
        auditSupport3Email: '',
        auditWarRoom1: '',
        auditWarRoom1Email: '',
        auditWarRoom2: '',
        auditWarRoom2Email: '',
        auditWarRoom3: '',
        auditWarRoom3Email: '',
        auditWarRoom4: '',
        auditWarRoom4Email: '',
        archived: false
    },
    message: '',
    messageSeverity: undefined,
    isOpen: false,
    externalAuditors: [],
    downloadUrl: '',
    downloadFilename: ''
};

export const reducer: Reducer<IAuditRequestState> = (state: IAuditRequestState | undefined, incomingAction: Action): IAuditRequestState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUDIT_REQUESTS':
            return {
                auditId: action.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: true,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            };
        case 'RECEIVE_AUDIT_REQUESTS':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.auditId === state.auditId) {
                return {
                    auditId: action.auditId,
                    auditRequests: action.auditRequests,
                    audit: state.audit,
                    isLoading: false,
                    message: state.message,
                    messageSeverity: state.messageSeverity,
                    isOpen: state.isOpen,
                    externalAuditors: state.externalAuditors,
                    downloadUrl: state.downloadUrl,
                    downloadFilename: state.downloadFilename
                };
            }
            break;
        case 'RECEIVE_AUDIT':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.auditId === state.auditId) {
                return {
                    auditId: action.auditId,
                    auditRequests: state.auditRequests,
                    audit: action.audit,
                    isLoading: state.isLoading,
                    message: state.message,
                    messageSeverity: state.messageSeverity,
                    isOpen: state.isOpen,
                    externalAuditors: state.externalAuditors,
                    downloadUrl: state.downloadUrl,
                    downloadFilename: state.downloadFilename
                };
            }
            break;
        case 'REQUEST_ADD_REQUEST':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: true,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'RECEIVE_ADD_REQUEST':
            return {
                auditId: state.auditId,
                auditRequests: [...state.auditRequests, action.addedRequest],
                audit: state.audit,
                isLoading: false,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'REQUEST_UPDATE_REQUEST':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: true,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'RECEIVE_UPDATE_REQUEST':
            var udatedList = [...state.auditRequests];
            var entry = udatedList.find(x => x.id === action.updatedRequest.id);

            if (entry) {
                entry.requestNumber = action.updatedRequest.requestNumber;
                entry.creationDate = action.updatedRequest.creationDate;
                entry.modifiedTimestamp = action.updatedRequest.modifiedTimestamp || '';
                entry.createdBy = action.updatedRequest.createdBy || '';
                entry.modifiedBy = action.updatedRequest.modifiedBy || '';
                entry.documentRequested = action.updatedRequest.documentRequested || '';
                entry.sme1 = action.updatedRequest.sme1 || '';
                entry.sme2 = action.updatedRequest.sme2 || '';
                entry.department = action.updatedRequest.department || '';
                entry.smeNotified = action.updatedRequest.smeNotified;
                entry.warRoomRecd = action.updatedRequest.warRoomRecd;
                entry.warRoomVerified = action.updatedRequest.warRoomVerified;
                entry.inAuditRoom = action.updatedRequest.inAuditRoom;
                entry.subjectWithAuditor = action.updatedRequest.subjectWithAuditor;
                entry.documentWithAuditor = action.updatedRequest.documentWithAuditor;
                entry.comments = action.updatedRequest.comments || '';
                entry.commentsBy = action.updatedRequest.commentsBy || '';
                entry.commentsByTimestamp = action.updatedRequest.commentsByTimestamp || '';
                entry.urgent = action.updatedRequest.urgent;
                entry.subjectClosed = action.updatedRequest.subjectClosed;
            }

            return {
                auditId: state.auditId,
                auditRequests: [...state.auditRequests],
                audit: state.audit,
                isLoading: false,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            };
        case 'RECEIVE_UPDATE_AUDIT':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: action.updatedAudit,
                isLoading: state.isLoading,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            };
        case 'SHOW_MESSAGE':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: action.isLoading,
                message: action.message,
                messageSeverity: action.messageSeverity,
                isOpen: true,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'HIDE_MESSAGE':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: state.isLoading,
                message: '',
                messageSeverity: undefined,
                isOpen: false,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'REQUEST_DELETE_REQUEST':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: true,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'RECEIVE_DELETE_REQUEST':
            if (action.requestId > 0) {
                const requestSearch = (element: IAuditRequest) => element.id === action.requestId;
                state.auditRequests.splice(state.auditRequests.findIndex(requestSearch), 1);
                return {
                    auditId: state.auditId,
                    auditRequests: state.auditRequests,
                    audit: state.audit,
                    isLoading: false,
                    message: state.message,
                    messageSeverity: state.messageSeverity,
                    isOpen: state.isOpen,
                    externalAuditors: state.externalAuditors,
                    downloadUrl: state.downloadUrl,
                    downloadFilename: state.downloadFilename
                }
            }
            break;
        case 'REQUEST_EXTERNAL_AUDITORS':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: true,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'RECEIVE_EXTERNAL_AUDITORS':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: false,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: action.externalAuditors,
                downloadUrl: state.downloadUrl,
                downloadFilename: state.downloadFilename
            }
        case 'REQUEST_EXPORT_AUDIT':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: true,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: '',
                downloadFilename: ''
            }
        case 'RECEIVE_EXPORT_AUDIT':
            return {
                auditId: state.auditId,
                auditRequests: state.auditRequests,
                audit: state.audit,
                isLoading: false,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                externalAuditors: state.externalAuditors,
                downloadUrl: action.downloadUrl,
                downloadFilename: action.downloadFilename
            }
    }

    return state;
};
