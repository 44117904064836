import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as AuditRequestsStore from '../store/AuditRequests';
import MaterialTable from 'material-table';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import ListAlt from '@material-ui/icons/ListAlt';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik } from 'formik';
import { userContext, IAuditor } from '../userContext';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { IAuthProviderProps } from '../auth/AuthProvider';
import { AuditRequestMailService } from '../services/AuditRequestMailService';
import { fetchMsGraph, GRAPH_ENDPOINTS } from "../auth/auth-utils";
import { TelemetryService } from '../services/TelemetryService';
import { AuditDocumentType } from '../models/Audit';

const styles = (theme: Theme) => createStyles({
  resize: { fontSize: '13px' },
  option: { fontSize: '12px' },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
});

const AutocompleteCustomPopper = (props: any) => {
  return (<Popper {...props} style={{ width: 200 }} placement='bottom-start' />)
}

const departmentList = [
  { name: 'Compliance', id: 1 },
  { name: 'Engineering', id: 2 },
  { name: 'Engineering - Avatar', id: 3 },
  { name: 'Entrepot', id: 4 },
  { name: 'HR', id: 5 },
  { name: 'IT', id: 6 },
  { name: 'Maintenance', id: 7 },
  { name: 'Prod - Liquid', id: 8 },
  { name: 'Prod - Pkg', id: 9 },
  { name: 'QA', id: 10 },
  { name: 'QC', id: 11 },
  { name: 'QC Micro', id: 12 },
  { name: 'Regulatory', id: 13 },
  { name: 'Serv. Techniques', id: 14 },
  { name: 'Validation Equip/Sys', id: 15 },
  { name: 'Validation Proc/Nett', id: 16 },
]

// At runtime, Redux will merge together...
type AuditRequestProps =
  AuditRequestsStore.IAuditRequestState // ... state we've requested from the Redux store
  & typeof AuditRequestsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ auditId: string }> // ... plus incoming routing parameters
  & WithStyles<typeof styles> // ... plus styles
  & IAuthProviderProps;

class AuditRequests extends React.PureComponent<AuditRequestProps> {

  static contextType = userContext;
  private auditId: number = -1;

  private auditorList: IAuditor[] = [];
  private auditorListIsLoading = false;
  private writePermission: boolean;
  private currentUser: string;
  private periodicInterval: any;
  private tableRef: any;

  constructor(props: any) {
    super(props);
    this.tableRef = React.createRef();
    this.handleBack = this.handleBack.bind(this);
    this.writePermission = false;
    this.currentUser = '';
  }

  // This method is called when the component is first added to the document
  async componentDidMount() {
    this.setPermissons();
    this.auditId = parseInt(this.props.match.params.auditId, 10) || -1;
    if (this.auditId >= 0) {
      this.props.requestAudit(this.auditId);
      this.props.requestAuditRequests(this.auditId);
      this.props.getExternalAuditors();

      // Setting periodic data refresh
      this.periodicInterval = setInterval(() => {
        // Checking if the table is not in editing mode
        if (this.tableRef &&
          this.tableRef.current &&
          this.tableRef.current.state &&
          this.tableRef.current.state.lastEditingRow === undefined &&
          this.tableRef.current.state.showAddRow === false) {
          this.props.requestAuditRequests(this.auditId);
        }
      }, 60000);
    }

    this.loadCurrentUser();
    await this.loadAuditorList();
  }

  async loadAuditorList() {
    if ((!this.auditorList || this.auditorList.length < 1) &&
    this.props.audit.siteId &&
    this.props.configuration &&
    !this.auditorListIsLoading) {
      try {
        this.auditorListIsLoading = true;
        if (this.props.audit.siteId === 1) {
          // Getting Candiac auditors
          this.auditorList = await this.getAuditors(this.props.configuration.candiacGroupId);
        } else if (this.props.audit.siteId === 2) {
          // Getting Royalmount auditors
          this.auditorList = await this.getAuditors(this.props.configuration.royalmountGroupId);
        }
      } finally {
        this.auditorListIsLoading = false
      }
    }
  }

  loadCurrentUser() {
    if (this.currentUser === '' && this.props.account) {
      this.currentUser = this.props.account.name;
    }
  }

  // This method is called when the route parameters change
  async componentDidUpdate(prevProps: any) {
    this.setPermissons();

    await this.loadAuditorList();
    this.loadCurrentUser();

    if (prevProps.downloadUrl !== this.props.downloadUrl && this.props.downloadUrl !== '') {
        const link = document.createElement('a')
        link.href = this.props.downloadUrl;
        link.download = this.props.downloadFilename;
        link.click();

        URL.revokeObjectURL(this.props.downloadUrl);
    }
  }

  async componentWillUnmount() {
    // Reseting periodic table data refresh
    clearInterval(this.periodicInterval);
  }

  private setPermissons() {
    if (this.props.account &&
      this.props.account.idToken &&
      this.props.account.idToken.roles) {
      if (this.props.account.idToken.roles.find((role: string) => role === 'Admin' || role === 'Auditor')) {
        this.writePermission = true;
      }
    }
  }

  async getAuditors(groupId: string): Promise<IAuditor[]> {
    try {
      const accessToken = await this.props.onRequestAdGroupToken();
      if (accessToken) {
        const groupMembers = await fetchMsGraph(
          GRAPH_ENDPOINTS.GROUP + groupId + '/members',
          accessToken
        ).catch((error) => {
          TelemetryService.trackException(error);
          this.props.showMessage("Error on getting auditors", "error");
        });

        let auditors: IAuditor[] = [];
        groupMembers.value.forEach((item: any) => {
          auditors.push({ fullName: item.displayName, email: item.userPrincipalName })
        });

        return auditors;
      }

      return [];
    }
    catch (err: any) {
      TelemetryService.trackException(err);
      // var error;
      if (typeof (err) === 'string') {
        // var errParts = err.split('|');
        // error = errParts.length > 1 ?
        //   { message: errParts[1], debug: errParts[0] } :
        //   { message: err };

        this.props.showMessage("Error on getting auditors", "error");
      } else {
        // error = {
        //   message: err.message,
        //   debug: JSON.stringify(err)
        // };

        this.props.showMessage("Error on getting auditors", "error");
      }
    }
    return [];
  }

  public render() {
    return (
      <React.Fragment>
        {this.renderAuditEdit()}
        {this.renderAuditRequestTable()}
        <Snackbar open={this.props.isOpen}
          autoHideDuration={6000}
          onClose={(event) => this.props.hideMessage()}>
          <Alert onClose={(event) => this.props.hideMessage()}
            severity={this.props.messageSeverity}>
            {this.props.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }

  private handleBack() {
    this.props.history.push("/");
  }

  private renderAuditEdit() {
    return (
      <div>
        <Grid container>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Button startIcon={<ArrowBackIosIcon />} onClick={this.handleBack}>Back</Button>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={10}>
            <Button
                variant="text"
                disabled={this.props.audit.id === 0}
                startIcon={<PictureAsPdf />}
                onClick={() => this.props.export(this.props.audit.id, AuditDocumentType.Pdf)}
                style={{float: 'right'}}>PDF</Button>
            <Button
                variant="text"
                disabled={this.props.audit.id === 0}
                startIcon={<ListAlt />}
                onClick={() => this.props.export(this.props.audit.id, AuditDocumentType.Spreadsheet)}
                style={{float: 'right'}}>Spreadsheet</Button>
          </Grid>
        </Grid>

        <Accordion defaultExpanded={true} style={{ padding: '0px 15px', marginTop: '10px' }}>
          <AccordionSummary id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content">
            <Typography className={this.props.classes.heading}>
              Audit - {this.props.audit.description}
            </Typography>
          </AccordionSummary>
          <Formik
            initialValues={this.props.audit}
            onSubmit={(values, actions) => {
              values.modifiedBy = this.currentUser;
              values.modifiedTimestamp = new Date().toISOString();
              this.props.updateAudit(values)
            }}
            enableReinitialize={true}>
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue
              } = props;
              return (
                <form onSubmit={handleSubmit} >
                  <fieldset disabled={!this.writePermission}>
                    <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField label="Description"
                          name="description"
                          fullWidth={true}
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <TextField label="Start Date"
                          name="auditDateStart"
                          fullWidth={true}
                          value={values.auditDateStart}
                          type="date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                          InputLabelProps={{
                            shrink: true,
                          }} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <TextField label="End Date"
                          name="auditDateEnd"
                          fullWidth={true}
                          value={values.auditDateEnd}
                          type="date"
                          disabled={isSubmitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditLead"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditLead}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditLead', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditLead', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="Audit Lead"
                              name="auditLead"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <TextField label="Auditor 1"
                          name="auditor1"
                          fullWidth={true}
                          value={values.auditor1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <TextField label="Auditor 2"
                          name="auditor2"
                          fullWidth={true}
                          value={values.auditor2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <TextField label="Auditor 3"
                          name="auditor3"
                          fullWidth={true}
                          value={values.auditor3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditSupport1"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditSupport1}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditSupport1', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditSupport1', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="Audit Suport 1"
                              name="auditSupport1"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditSupport2"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditSupport2}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditSupport2', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditSupport2', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="Audit Suport 2"
                              name="auditSupport2"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditSupport3"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditSupport3}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditSupport3', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditSupport3', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="Audit Suport 3"
                              name="auditSupport3"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditWarRoom1"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditWarRoom1}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditWarRoom1', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditWarRoom1', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="War Room 1"
                              name="auditWarRoom1"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditWarRoom2"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditWarRoom2}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditWarRoom2', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditWarRoom2', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="War Room 2"
                              name="auditWarRoom2"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditWarRoom3"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          value={values.auditWarRoom3}
                          disabled={!this.writePermission}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditWarRoom3', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditWarRoom3', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="War Room 3"
                              name="auditWarRoom3"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Autocomplete id="auditWarRoom4"
                          freeSolo
                          options={this.auditorList.map((option) => option.fullName)}
                          disabled={!this.writePermission}
                          value={values.auditWarRoom4}
                          onChange={(event, newValue, reason) => {
                            setFieldValue('auditWarRoom4', newValue);
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            if (event.target instanceof HTMLInputElement) {
                              let newValue = (event.target as HTMLInputElement).value;
                              setFieldValue('auditWarRoom4', newValue);
                              handleBlur(event);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params}
                              label="War Room 4"
                              name="auditWarRoom4"
                              disabled={isSubmitting} />)} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                          <Button type="submit"
                            variant="outlined"
                            disabled={!dirty || isSubmitting}
                            startIcon={isSubmitting ? <HourglassFullIcon /> : <SaveIcon />}
                            style={{ marginTop: "10px", float: 'right' }}>
                            Save
                          </Button>
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              );
            }}
          </Formik>
        </Accordion>
      </div>
    );
  }



  private renderAuditRequestTable() {
    const onSmeNotifiedClicked = (props: any, value: boolean): void => {
      var auditRequest: any = {};
      props.onChange(value);
      Object.assign(auditRequest, props.rowData);
      auditRequest.smeNotified = value;
      AuditRequestMailService.sendMail(auditRequest);
    }

    const columns = [
      { title: '#', field: 'requestNumber', width: 50, editable: 'never' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Created Date', field: 'creationDate', type: 'datetime' as const, editable: 'never' as const, initialEditValue: new Date(), cellStyle: { fontSize: '0.75rem' } },
      { title: 'Urgent', field: 'urgent', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Requestor', field: 'createdBy', cellStyle: { fontSize: '0.75rem' }, initialEditValue: this.currentUser },
      { title: 'Document Requested', field: 'documentRequested', cellStyle: { fontSize: '0.75rem' } },
      {
        title: 'SME1', field: 'sme1', cellStyle: { fontSize: '0.75rem' }, editComponent: (props: any) => (
          <Autocomplete
            id="sme1-autocomplete"
            PopperComponent={AutocompleteCustomPopper}
            classes={{ option: this.props.classes.option }}
            options={this.props.externalAuditors}
            value={props.value}
            onChange={(event, newValue, reason) => {
              props.onChange(newValue)
            }}
            onBlur={(event) => {
              if (event.target instanceof HTMLInputElement) {
                let newValue = (event.target as HTMLInputElement).value;
                if (!this.props.externalAuditors.includes(newValue)) {
                  this.props.externalAuditors.push(newValue);
                }
                props.onChange(newValue)
              }
            }}
            renderInput={(params) => (
              <TextField {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: this.props.classes.resize,
                  },
                }} />
            )} />
        )
      },
      {
        title: 'SME2', field: 'sme2', cellStyle: { fontSize: '0.75rem' }, editComponent: (props: any) => (
          <Autocomplete
            id="sme2-autocomplete"
            PopperComponent={AutocompleteCustomPopper}
            classes={{ option: this.props.classes.option }}
            options={this.props.externalAuditors}
            value={props.value}
            onChange={(event, newValue, reason) => props.onChange(newValue)}
            onBlur={(event) => {
              if (event.target instanceof HTMLInputElement) {
                let newValue = (event.target as HTMLInputElement).value;
                props.onChange(newValue)
              }
            }}
            renderInput={(params) => (
              <TextField {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: this.props.classes.resize,
                  },
                }} />
            )} />
        )
      },
      {
        title: 'Dept', field: 'department', cellStyle: { fontSize: '0.75rem' }, editComponent: (props: any) => (
          <Autocomplete
            id="department-autocomplete"
            PopperComponent={AutocompleteCustomPopper}
            classes={{ option: this.props.classes.option }}
            options={departmentList.map((option) => option.name)}
            value={props.value}
            onChange={(event, newValue, reason) => props.onChange(newValue)}
            renderInput={(params) => (
              <TextField {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: this.props.classes.resize,
                  },
                }} />
            )}
          />)
      },
      {
        title: 'SME Notified', field: 'smeNotified', type: 'boolean' as const, class: "PrivateSwitchBase-input-36", cellStyle: { fontSize: '0.75rem' }, editComponent: (props: any) => (
          <label className="sme-notified-container">
            <input
              type="checkbox"
              checked={props.value}
              className="sme-notified-checkbox"
              onChange={e => onSmeNotifiedClicked(props, Boolean(e.target.checked))} />
            <span className="sme-notified-checkmark"></span>
          </label>
        )
      },
      { title: 'WarRoom Recorded', field: 'warRoomRecd', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Verified', field: 'warRoomVerified', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'In Audit Room', field: 'inAuditRoom', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Subject With Auditor', field: 'subjectWithAuditor', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Subject Closed', field: 'subjectClosed', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Document With Auditor', field: 'documentWithAuditor', type: 'boolean' as const, cellStyle: { fontSize: '0.75rem' } },
      { title: 'Comments', field: 'comments', cellStyle: { fontSize: '0.75rem' } },
    ];

    return (
      <div style={{ marginTop: "30px" }}>
        <MaterialTable
          title="Audit Requests"
          tableRef={this.tableRef}
          data={this.props.auditRequests}
          columns={columns}
          localization={{ header: { actions: '' } }}
          isLoading={this.props.isLoading}
          options={{
            padding: "dense",
            paging: false,
            headerStyle: { position: 'sticky', top: 0, fontWeight: 600, fontSize: '0.75rem' },
            maxBodyHeight: '67vh',
            rowStyle: row => {
              let rowStyle: any = {};
              rowStyle.fontSize = 20;
              if (row.tableData.id % 2) rowStyle.backgroundColor = "#f4f4f4";
              if (row.urgent) rowStyle.backgroundColor = "#ffc7cE";
              if (row.subjectWithAuditor) rowStyle.backgroundColor = "#ffeb9c";
              if (row.subjectClosed) rowStyle.backgroundColor = "#c6efce";
              if (row.documentWithAuditor) rowStyle.backgroundColor = "#9bc2e6";
              return rowStyle;
            },
            addRowPosition: 'first',
            actionsColumnIndex: -1,
          }}
          editable={
            {
              onRowAdd: (newData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve({});
                    if (this.auditId >= 0) {
                      newData.auditId = this.auditId;
                      this.props.addRequest(newData);
                    } else {
                      this.props.showMessage('Unabable to add new request: Undefined Audit ID', 'error')
                    }
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve({});
                    if (oldData) {
                      newData.modifiedBy = this.currentUser;
                      newData.modifiedTimestamp = new Date().toISOString();
                      if (oldData.comments !== newData.comments) {
                        newData.commentsBy = this.currentUser;
                        newData.commentsByTimestamp = new Date().toISOString();
                      }
                      this.props.updateRequest(newData);
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve({});
                    if (oldData) {
                      this.props.deleteRequest(oldData.id);
                    }
                  }, 600);
                }),
            }
          }
        />
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.auditRequests, // Selects which state properties are merged into the component's props
  AuditRequestsStore.actionCreators // Selects which action creators are merged into the component's props
)(withStyles(styles)(AuditRequests as any));
