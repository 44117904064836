import { IAuditRequest } from "../store/AuditRequests";

export const AuditRequestMailService = {
    sendMail(auditRequest: IAuditRequest) {
        var email = '%20';
        var subject = `${isUrgent(auditRequest.urgent)}AUDIT REQUEST : Request #${getRequestNumber(auditRequest)}`;
        var body = getBody(auditRequest);

        if (isSendMail(auditRequest)) {
            window.open(`mailto:${email}?subject=${subject}&body=${body}`);
        }
    }
}

function isUrgent(value: Boolean): String {
    return value ? 'URGENT ' : '';
}

function isSendMail(auditRequest: IAuditRequest): Boolean {
    return (!!auditRequest.sme1 || !!auditRequest.sme2) && auditRequest.smeNotified && !!auditRequest.requestNumber;
}

function getRequestNumber(auditRequest: IAuditRequest): string {
    return auditRequest?.requestNumber ? auditRequest.requestNumber.toString() : '';
}

function getBody(auditRequest: IAuditRequest): String {
    return `
Request Number : ${getRequestNumber(auditRequest)}%0D%0A
Document requested : ${auditRequest.documentRequested ?? ''}%0D%0A
Request date : ${auditRequest.creationDate}%0D%0A
Created by : ${auditRequest.createdBy}%0D%0A
SME1 : ${auditRequest.sme1 ?? ''} %0D%0A
SME2 : ${auditRequest.sme2 ?? ''} %0D%0A
Department : ${auditRequest.department ?? ''}%0D%0A
---------%0D%0A
Comments : ${auditRequest.comments ?? ''}%0D%0A

AVIS DE CONFIDENTIALITÉ : Ce message peut contenir de l'information légalement privilégiée ou confidentielle. Si vous n'êtes pas le destinataire ou croyez avoir reçu par erreur ce message, nous vous saurions gré d'en aviser l'émetteur et d'en détruire le contenu sans le communiquer à d'autres ou le reproduire. CONFIDENTIALITY NOTICE: This communication may contain privileged or confidential information. If you are not the intended recipient or received this communication by error, please notify the sender and delete the message without copying or disclosing it.
`;
}