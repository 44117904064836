import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

export default class Layout extends React.PureComponent {
    render() {
        return (
            <div>
                <NavMenu {...this.props} />
                <Container fluid={true}>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}