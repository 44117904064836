import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as AuditListStore from '../store/AuditList';
import { ISite } from '../store/AuditList';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import {userContext} from '../userContext';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Grid from '@material-ui/core/Grid';
import { IAudit } from '../models/Audit';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { IAuthProviderProps } from '../auth/AuthProvider';

const AutocompleteCustomPopper = (props: any) => {
  return (<Popper {...props} style={{ width: 200 }} placement='bottom-start'/>)
}

const siteList: ISite[] = [
  {  name: 'Candiac', id: 1 },
  {  name: 'Royalmount', id: 2 },
]

// At runtime, Redux will merge together...
type AuditListProps =
    AuditListStore.IAuditListState // ... state we've requested from the Redux store
  & typeof AuditListStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps // ... plus incoming routing parameters
  & IAuthProviderProps;

class Home extends React.PureComponent<AuditListProps> {

  static contextType = userContext;
  private writePermission: boolean = false;
  private isAdmin: boolean = false;
  private numberOfRetries: number = 0;
  
  public componentDidMount() {
    if(this.props.selectedSite && this.props.selectedSite.id > 0){
      this.props.requestAuditList(this.props.selectedSite, this.props.isArchived);
    } else if (this.props.graphProfile) {
      let userSite = this.getSite(this.props.graphProfile.officeLocation);
        if(userSite){
          this.context.selectedSiteId = userSite.id;
          this.props.requestAuditList(userSite, this.props.isArchived);
        } 
    };

    this.setPermissons();
  }

  public componentDidUpdate(prevProps: AuditListStore.IAuditListState) {
    this.setPermissons();

    if(this.props.selectedSite.id < 1){
      if(this.props.graphProfile) {
        let userSite = this.getSite(this.props.graphProfile.officeLocation);
        if(userSite) {
          this.context.selectedSiteId = userSite.id;
          this.props.requestAuditList(userSite, this.props.isArchived);
        } 
      }
    } 
    else if (this.props.isInErrorState) {
      if(this.numberOfRetries < 10) {
        this.props.requestAuditList(this.props.selectedSite);
        this.numberOfRetries = this.numberOfRetries + 1;
      } else {
        this.props.showMessage("Failed to get audits after " + this.numberOfRetries + " tries. Try reloading the page." , "error");
      }
    }     

    if(prevProps.newAuditId !== this.props.newAuditId) {
      // A new audit has been added, redirecting to edit page
      this.props.history.push("/audit-requests/" + this.props.newAuditId);
    }
  }

  private setPermissons()
  {
    if(this.props.account && 
       this.props.account.idToken && 
       this.props.account.idToken.roles) {
      if(this.props.account.idToken.roles.find((role: string) => role === 'Admin')) {
        this.writePermission = true;
        this.isAdmin = true;
      }
      else if(this.props.account.idToken.roles.find((role: string) => role === 'Auditor')) {
        this.writePermission = true;
      }
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.renderAuditListTable()}
        <Snackbar open={this.props.isOpen} 
                  autoHideDuration={10000}
                  onClose={(event) => this.props.hideMessage()}>
          <Alert onClose={(event) => this.props.hideMessage()} 
                 severity={this.props.messageSeverity}>
            {this.props.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }

  private getSite(siteName: string): ISite | undefined{
    return siteList.find(item => item.name.localeCompare(siteName, undefined, { sensitivity: 'accent' }) === 0);
  }

  private renderAuditListTable() {

    const tableActions: any[] = this.isAdmin ? [
      { 
        icon: 'launch',
              tooltip: 'Open',
              position: 'row',                 
              onClick: (event: any, rowData: any) => 
                this.props.history.push('/audit-requests/' + (rowData as IAudit).id) 
      },
      { 
        icon: 'archive',
        tooltip: 'Archive',
        hidden: this.props.isArchived,
        onClick: (event: any, selectedItems: any) => 
          this.props.archive((selectedItems as IAudit[]).map(item => item.id))
      },
      { 
        icon: 'unarchive',
        tooltip: 'Unarchive',
        hidden: !this.props.isArchived,
        onClick: (event: any, selectedItems: any) => 
          this.props.unarchive((selectedItems as IAudit[]).map(item => item.id))
      }
    ] : [
      { 
        icon: 'launch',
        tooltip: 'Open',
        position: 'row',                 
        onClick: (event: any, rowData: any) => 
        this.props.history.push('/audit-requests/' + (rowData as IAudit).id) 
      } 
    ];

    const columns = [
        { title: 'Audit Start', 
          field: 'auditDateStart', 
          type: 'date' as const, 
          width: 100,
          cellStyle: {fontSize: '0.75rem'},
          defaultSort: 'desc' as const,
        },
        { title: 'Description', field: 'description', cellStyle: {fontSize: '0.75rem'} },
        { title: 'Requests', align: 'center' as const, field: 'numberOfRequests', cellStyle: {fontSize: '0.75rem'} },
        { title: 'Auditor 1', field: 'auditor1', cellStyle: {fontSize: '0.75rem'} },
        { title: 'Auditor 2', field: 'auditor2', cellStyle: {fontSize: '0.75rem'} },
        { title: 'Audit Lead', field: 'auditLead', cellStyle: {fontSize: '0.75rem'} },
        { title: 'Audit Support', field: 'auditSupport1', cellStyle: {fontSize: '0.75rem'} },
        { title: 'War Room 1', field: 'auditWarRoom1', cellStyle: {fontSize: '0.75rem'} },
        { title: 'War Room 2', field: 'auditWarRoom2', cellStyle: {fontSize: '0.75rem'} },
    ];

    return (
      <div>
        <Grid container>
          <Grid item xs={4} sm={4} md={4} lg={2}>
          <Autocomplete id="site-autocomplete"
                        PopperComponent={AutocompleteCustomPopper}
                        options={siteList}
                        getOptionLabel={(option) => option.name}
                        value={this.props.selectedSite}
                        onChange={(event, newValue, reason) => {
                          if(newValue) { 
                            this.context.selectedSiteId = newValue.id;
                            this.props.requestAuditList(newValue, this.props.isArchived); 
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params}             
                            InputProps={{ ...params.InputProps,
                          }} />
                        )} />
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={10}>
            <Button style={{float: 'right', marginBottom: '15px'}} 
                    variant="outlined" 
                    startIcon={<AddIcon />} 
                    disabled={!this.writePermission}
                    hidden={this.props.isArchived}
                    onClick={() => {
                      this.props.addAudit({            
                        siteId: this.props.selectedSite.id, 
                        creationDate: new Date().toISOString(),
                        createdBy: this.props.account.name} as IAudit);
                      }}>
              Add Audit
            </Button>
            { this.isAdmin ? 
              <Button style={{float: 'right', marginBottom: '15px', marginRight: '10px'}} 
                      startIcon={this.props.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />} 
                      disabled={!this.writePermission}
                      onClick={() => {
                          if(this.props.isArchived)
                            this.props.requestAuditList(this.props.selectedSite, false);
                          else
                            this.props.requestAuditList(this.props.selectedSite, true);
                        }
                      }>
                Show {this.props.isArchived ? 'Unarchived' : 'Archived'}
              </Button> 
              : null 
            }
          </Grid>
          <Grid item xs={12}>
            <MaterialTable  title={this.props.isArchived ? "Audit Tracking Log [Archived]" : "Audit Tracking Log"}
                            data={this.props.auditList as IAudit[]}
                            columns={columns}
                            localization={{header: {actions: ''}}}
                            isLoading={this.props.isLoading}
                            actions={tableActions}
                            options={{ 
                              paging: false, 
                              padding: "dense", 
                              headerStyle: { position: 'sticky', 
                                            top: 0, 
                                            fontWeight: 600, 
                                            fontSize: '0.75rem'
                              },
                              maxBodyHeight: '76vh',
                              rowStyle: row => {
                                return row.tableData.id % 2 ?  
                                      { backgroundColor: "#f4f4f4", fontSize: 20 } :
                                      { fontSize: 20 }
                              },
                              actionsColumnIndex: -1,
                              selection: this.isAdmin,
                            }}
                            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.auditList, // Selects which state properties are merged into the component's props
  AuditListStore.actionCreators // Selects which action creators are merged into the component's props
)(Home as any);