import { IConfiguration } from "../models/Configuration";
import axios from 'axios';

export const ConfigurationService = {
    async getConfig(): Promise<IConfiguration> {
        if (config) {
            return config;
        }
        try {
            config = await (await axios.get(`configuration`)).data;
        } catch (error) {
            throw new Error('Failed to retrieve configuration');
        }

        return config;
    }
}

let config: IConfiguration;