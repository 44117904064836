import React, { Component } from "react";
import {
    requiresInteraction,
    fetchMsGraph,
    GRAPH_ENDPOINTS,
    GRAPH_REQUESTS,
    API_REQUESTS
} from "./auth-utils";
import { UserAgentApplication, WindowUtils, Logger, LogLevel } from "msal";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ConfigurationService } from "../services/ConfigurationService";
import { TelemetryService } from "../services/TelemetryService";
import axios from "axios";


export interface IAuthProviderProps {
    account: any,
    graphProfile: any,
    configuration: any,
    onRequestAdGroupToken: any
}

export default (C: any) => class AuthProvider extends Component<any, any> {

    private msalApp: UserAgentApplication | null;

    private tokenRefreshInterval: any;

    private axiosInterceptor: number | null;

    constructor(props: any) {
        super(props);

        this.state = {
            authenticationInProgress: true,
            authenticationError: false,
            isInitialized: false,
            account: null,
            error: null,
            emailMessages: null,
            graphProfile: null,
            configuration: null,
        };

        this.msalApp = null;
        this.axiosInterceptor = null;
    }

    async componentDidMount() {
        if (!await this.loadConfiguration()) {
            this.setErrorState('Could not load this organization configuration.')
            return;
        }

        this.initMsalClient();

        await this.authenticate();
    }

    async componentWillUnmount() {
        this.stopSilentTokenAutoRefresh();
        this.removeAxiosInterceptor()
    }

    async onSignIn() {
        await this.authenticate();
    }

    onSignOut() {
        this.stopSilentTokenAutoRefresh();
        this.removeAxiosInterceptor();
        this.msalApp?.logout();
    }

    async onRequestAdGroupToken() {
        let [token, _] = await this.acquireSilentToken(GRAPH_REQUESTS.GROUP);

        return token;
    }

    async loadConfiguration(): Promise<boolean> {

        var config = await ConfigurationService.getConfig().catch(error => {
            TelemetryService.trackException(error);
            return false;
        });

        this.setState({
            configuration: config
        });

        return true;
    }

    private initMsalClient() {
        this.msalApp = new UserAgentApplication({
            auth: {
                clientId: this.state.configuration.clientId,
                authority: this.state.configuration.authenticationAuthority + '/' + this.state.configuration.tenantId,
                redirectUri: document.getElementById('root')?.baseURI,
                validateAuthority: true,
                postLogoutRedirectUri: document.getElementById('root')?.baseURI,
                navigateToLoginRequestUrl: false
            },

            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false
            },
            system: {
                navigateFrameWait: 500,
                logger: new Logger((logLevel, message) => {
                    console.log(message);
                }, {
                    level: LogLevel.Error,
                    piiLoggingEnabled: true
                }),
                telemetry: {
                    applicationName: "pms-audit-tracking",
                    applicationVersion: "0.8.0",
                    telemetryEmitter: (events) => {
                        console.log('Telemetry Events:', events);
                    }
                }
            }
        });

        this.msalApp?.handleRedirectCallback(error => {
            if (error) {
                TelemetryService.trackException(error);
                this.setErrorState(error.errorMessage);
            }
        });

        this.setState({
            isInitialized: true
        });
    }

    async acquireSilentToken(request: any): Promise<[string | undefined, boolean]> {
        let requiresLogin = false;
        let authResponse = null;

        try {
            authResponse = await this.msalApp?.acquireTokenSilent(request);
        } catch (error) {
            let anyError = error as any;
            requiresLogin = requiresInteraction(anyError.errorCode);

            if (!requiresLogin) {
                TelemetryService.trackException(error);
            }
        }

        return [authResponse?.accessToken, requiresLogin];
    }

    acquireTokenThroughLoginRedirection(request: any) {
        this.msalApp?.loginRedirect({
            ...request,
            redirectUri: document.getElementById('root')?.baseURI
        })
    }

    async getGraphProfile(accessToken: string): Promise<any> {
        let graphProfile = null;

        if (WindowUtils.isInIframe()) return graphProfile;

        graphProfile = await fetchMsGraph(
            GRAPH_ENDPOINTS.ME,
            accessToken
        ).catch(error => {
            TelemetryService.trackException(error);
        });

        return graphProfile;
    }

    setUnauthenticatedState() {
        this.setState({
            account: null,
            authenticationInProgress: true,
            error: null,
            authenticationError: false,
            graphProfile: null
        });
    }

    setAuthenticatedState(account: any, graphProfile: any) {
        this.setState({
            account: account,
            authenticationInProgress: false,
            error: null,
            authenticationError: false,
            graphProfile: graphProfile
        });
    }

    setErrorState(message: any = undefined) {
        const error = message ? message : 'Unable to authenticate.';

        console.error(error);

        this.setState({
            account: null,
            authenticationInProgress: false,
            error: error,
            authenticationError: true,
            graphProfile: null
        });
    }

    installAxiosInterceptor() {
        const msalApp = this.msalApp;

        this.removeAxiosInterceptor();

        this.axiosInterceptor = axios.interceptors.request.use(async (config) => {
            const authResponse = await msalApp!.acquireTokenSilent(API_REQUESTS.LOGIN);
            config.headers.common['authorization'] = `Bearer ${authResponse?.accessToken}`;
            return config;
        });
    }

    removeAxiosInterceptor() {
        if (this.axiosInterceptor != null) {
            axios.interceptors.request.eject(this.axiosInterceptor);
            this.axiosInterceptor = null;
        }
    }

    async authenticate() {
        if (!this.msalApp) {
            this.setErrorState('Cannot authenticate as MSAL is not properly initialized.')
            return;
        }

        this.setUnauthenticatedState();

        const [loginToken, requiresLogin] = await this.acquireSilentToken(GRAPH_REQUESTS.LOGIN);

        if (requiresLogin) {
            this.acquireTokenThroughLoginRedirection(GRAPH_REQUESTS.LOGIN);
            return;
        }

        if (!loginToken) {
            this.setErrorState('Authentication failed.');
            return;
        }

        const [webApiToken, _] = await this.acquireSilentToken(API_REQUESTS.LOGIN);
        const account = this.msalApp.getAccount();
        const graphProfile = await this.getGraphProfile(loginToken);

        if (webApiToken && account && graphProfile) {
            axios.defaults.headers.common['authorization'] = `Bearer ${webApiToken}`;

            this.startSilentTokenAutoRefresh();
            this.installAxiosInterceptor();

            this.setAuthenticatedState(account, graphProfile);
        } else {
            this.setErrorState('Authentication succeeded but could not fetch required user details.')
        }
    }

    async refreshTokens() {
        const [loginToken, _] = await this.acquireSilentToken(GRAPH_REQUESTS.LOGIN);
        const [webApiToken, __] = await this.acquireSilentToken(API_REQUESTS.LOGIN);

        if (webApiToken) {
            axios.defaults.headers.common['authorization'] = `Bearer ${webApiToken}`;
        }

        if (!loginToken || !webApiToken) {
            console.error('Silent token refresh failed.')
        }
    }

    startSilentTokenAutoRefresh() {
        this.stopSilentTokenAutoRefresh();

        this.tokenRefreshInterval = setInterval(async () => {
            await this.refreshTokens();
        }, 1800000); // 30 mins
    }

    stopSilentTokenAutoRefresh() {
        if (this.tokenRefreshInterval) {
            clearInterval(this.tokenRefreshInterval);
            this.tokenRefreshInterval = null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.state.authenticationInProgress}>
                    <Container style={{ padding: '15px' }} >
                        <div style={{ display: 'flex' }}>
                            <span><CircularProgress /></span>
                            <span style={{ marginTop: '10px', marginLeft: '10px' }}>
                                Authentication in progress...
                            </span>
                        </div>
                    </Container>
                </Dialog>
                <Dialog open={this.state.authenticationError}>
                    <Container style={{ padding: '15px' }} >
                        <h5>Authentication</h5>
                        <div>An error occured during authentication.</div>
                        {
                            this.state.isInitialized ?
                                <div>
                                    <div>Please try to sign in again using an active Pharmascience account.</div>
                                    <Button variant="contained"
                                        color="primary"
                                        onClick={() => this.onSignIn()}
                                        size='small'
                                        style={{ float: 'right', marginTop: '10px' }}>
                                        Sign in
                                    </Button>
                                </div>
                                :
                                <div>Please contact your administrator.</div>
                        }
                    </Container>
                </Dialog>
                {
                    this.state.authenticationInProgress ? null :
                        <C
                            {...this.props}
                            account={this.state.account}
                            emailMessages={this.state.emailMessages}
                            error={this.state.error}
                            graphProfile={this.state.graphProfile}
                            configuration={this.state.configuration}
                            onSignIn={() => this.onSignIn()}
                            onSignOut={() => this.onSignOut()}
                            onRequestAdGroupToken={() =>  this.onRequestAdGroupToken()}
                        />
                }
            </React.Fragment>
        );
    }
};


