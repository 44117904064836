export const requiresInteraction = (errorMessage: any) => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1 ||
        errorMessage.indexOf("user_login_error") > -1
    );
};

export const fetchMsGraph = async (url: any, accessToken: any) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    WEB_API_ACCESS: "api://369eb8c0-8ac7-4e4b-9ee8-1cccc0cf9eb2/webapi",
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    GROUP: "https://graph.microsoft.com/v1.0/groups/"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    GROUP: {
        scopes: [GRAPH_SCOPES.USER_READ]
    }
};

export const API_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.WEB_API_ACCESS,
        ]
    },
};



