import { ConfigurationService } from "./ConfigurationService";
import { ApplicationInsights, SeverityLevel, ITelemetryItem, Trace } from "@microsoft/applicationinsights-web";
import { AxiosResponse } from 'axios';

// internal interfaces from applicationinsights-common
interface IExceptionInternal {
    ver: string;
    id: string;
    exceptions: IExceptionDetailsInternal[];
    severityLevel?: SeverityLevel | number;
    problemGroup: string;
    isManual: boolean;
}
interface IExceptionDetailsInternal {
    id: number;
    outerId: number;
    typeName: string;
    message: string;
    hasFullStack: boolean;
    stack: string;
    parsedStack: IExceptionStackFrameInternal[];
}
interface IExceptionStackFrameInternal {
    level: number;
    method: string;
    assembly: string;
    fileName: string;
    line: number;
    pos?: number;
}

export const TelemetryService = {
    async initTelemetry(): Promise<void> {
        const config = await ConfigurationService.getConfig();

        // https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=snippet#configuration
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: config.applicationInsightKey,
                disableExceptionTracking: false,
                disableTelemetry: false,
                disableAjaxTracking: false,
                enableUnhandledPromiseRejectionTracking: false,
            }
        });
        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer(this.filterIgnoredExceptions);

        window.addEventListener('error', (event) => {
            appInsights.trackException({ exception: new Error(event.message) });
        });
    },
    trackException(error: any, properties: any = {}) {
        appInsights.trackException({ exception: error, properties: properties });
    },
    trackErrorResponse(message: string, errorResponse: AxiosResponse<any>) {
        const xhrRequest = errorResponse.request as XMLHttpRequest;
        const xhrResponse = xhrRequest.response as Response;
        if (xhrResponse.bodyUsed) {
            xhrResponse.text().then((text: any) => {
                appInsights.trackException({
                    exception: new Error(message),
                    properties: {
                        body: text,
                        errorCode: errorResponse.status,
                        url: errorResponse.config.url
                    }
                });
            });
        }

        appInsights.trackException({
            exception: new Error(message),
            properties: {
                errorCode: errorResponse.status,
                url: errorResponse.config.url
            }
        });
    },
    filterIgnoredExceptions(item: ITelemetryItem) {
        if (item.baseType === 'ExceptionData') {
            const exceptionData = item.baseData as IExceptionInternal;
            appInsights.trackTrace({
                message: 'filterIgnoredExceptions: ' + exceptionData.exceptions[0].message,
                severityLevel: SeverityLevel.Information
            });
            return !this.isIgnoredException(exceptionData.exceptions[0].message);
        }

        return true;
    },
    isIgnoredException(message: string): boolean {
        return message === "ClientAuthError: Token renewal operation failed due to timeout." // Rather clear that there's not much we can do...
            || message === "Network Error"; // Very general category, but was also generally observed due to transient VPN issues blocking the connection
    }
}

let appInsights: ApplicationInsights;
