import { Action, Reducer } from 'redux';
import axios from 'axios';
import { AppThunkAction } from '.';
import { IAudit } from '../models/Audit';
import { TelemetryService } from '../services/TelemetryService';

type Severity = "error" | "success" | "info" | "warning" | undefined;

export interface ISite {
    id: number;
    name: string;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IAuditListState {
    isLoading: boolean;
    isArchived: boolean;
    auditList: IAudit[];
    newAuditId: number;
    selectedSite: ISite;
    message: string;
    messageSeverity: Severity;
    isOpen: boolean;
    isInErrorState: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestAuditListAction {
    type: 'REQUEST_AUDIT_LIST';
    site: ISite;
}

interface ReceiveAuditListAction {
    type: 'RECEIVE_AUDIT_LIST';
    auditList: IAudit[];
    isArchived: boolean;
}

interface ReceiveAuditListError {
    type: 'RECEIVE_AUDIT_LIST_ERROR';
}

interface ReceiveAddAudit {
    type: 'RECEIVE_ADD_AUDIT';
    addedAudit: IAudit;
}

interface RequestAuditArchiveAction {
    type: 'REQUEST_AUDIT_ARCHIVE';
}

interface ReceiveAuditArchivetAction {
    type: 'RECEIVE_AUDIT_ARCHIVE';
    archivedIds: number[];
}

interface RequestAuditUnarchiveAction {
    type: 'REQUEST_AUDIT_UNARCHIVE';
}

interface ReceiveAuditUnarchivetAction {
    type: 'RECEIVE_AUDIT_UNARCHIVE';
    unarchivedIds: number[];
}

interface ShowMessageAction {
    type: 'SHOW_MESSAGE';
    message: string;
    messageSeverity: Severity;
}

interface HideMessageAcion {
    type: 'HIDE_MESSAGE';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAuditListAction |
    ReceiveAuditListAction |
    ReceiveAuditListError |
    ReceiveAddAudit |
    RequestAuditArchiveAction |
    ReceiveAuditArchivetAction |
    RequestAuditUnarchiveAction |
    ReceiveAuditUnarchivetAction |
    ShowMessageAction |
    HideMessageAcion;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAuditList: (site: ISite, archived?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Load data if not already loading
        const appState = getState();
        if (appState && appState.auditList && !appState.auditList.isLoading) {
            let queryString = '';
            if (archived === null || archived === undefined) {
                queryString = `audit?siteId=${site.id}`;
            } else if (archived) {
                queryString = `audit?siteId=${site.id}&archived=true`;
            } else {
                queryString = `audit?siteId=${site.id}&archived=false`
            }
            axios.get(queryString)
                .then(response => {
                    let contentType = response.headers['content-type']
                    if (contentType?.includes('application/json')) {
                        return response.data as Promise<IAudit[]>
                    } else {
                        TelemetryService.trackErrorResponse(`Error loading audit for site ${site.name}`, response);
                        return Promise.reject(new Error(`Error loading audit for site ${site.name}`));
                    }
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_AUDIT_LIST', auditList: data, isArchived: archived ? true : false });
                }).catch(error => {
                    console.log(error);
                    if (appState && appState.auditList)
                        dispatch({ type: 'RECEIVE_AUDIT_LIST_ERROR' });
                });

            dispatch({ type: 'REQUEST_AUDIT_LIST', site: site });
        }
    },
    addAudit: (newAudit: IAudit): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.auditList && !appState.auditList.isLoading) {
            axios.post(`audit`, newAudit)
                .then(response =>
                    response.data as Promise<number>)
                .then(data => {
                    newAudit.id = data;
                    dispatch({ type: 'RECEIVE_ADD_AUDIT', addedAudit: newAudit })
                }).catch(error => {
                    TelemetryService.trackException(error);
                    dispatch({ type: 'SHOW_MESSAGE', message: 'Error on creating new audit', messageSeverity: 'error' });
                });
        }
    },
    archive: (idsToArchive: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState &&
            appState.auditList &&
            !appState.auditList.isLoading &&
            idsToArchive &&
            idsToArchive.length > 0) {

            axios.put(`audit/archive`, idsToArchive).then(response => {
                dispatch({ type: 'RECEIVE_AUDIT_ARCHIVE', archivedIds: idsToArchive });
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Successfully archived ' + idsToArchive.length + (idsToArchive.length > 1 ? ' audits' : ' audit'),
                    messageSeverity: 'success'
                });

            }).catch(error => {
                TelemetryService.trackException(error);
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Unexpected error on archiving audits',
                    messageSeverity: 'error'
                });
            });

            dispatch({ type: 'REQUEST_AUDIT_ARCHIVE' });
        }
    },
    unarchive: (idsToUnarchive: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState &&
            appState.auditList &&
            !appState.auditList.isLoading &&
            idsToUnarchive &&
            idsToUnarchive.length > 0) {

            axios.put(`audit/archive`, idsToUnarchive).then(response => {
                dispatch({ type: 'RECEIVE_AUDIT_UNARCHIVE', unarchivedIds: idsToUnarchive });
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Successfully unarchived ' + idsToUnarchive.length + (idsToUnarchive.length > 1 ? ' audits' : ' audit'),
                    messageSeverity: 'success'
                });
            }).catch(error => {
                TelemetryService.trackException(error);
                dispatch({
                    type: 'SHOW_MESSAGE',
                    message: 'Unexpected error on unarchiving audits',
                    messageSeverity: 'error'
                });
            });

            dispatch({ type: 'REQUEST_AUDIT_UNARCHIVE' });
        }
    },
    showMessage: (message: string, messageSeverity: Severity): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.auditList && message) {
            dispatch({ type: 'SHOW_MESSAGE', message: message, messageSeverity: messageSeverity });
        }
    },
    hideMessage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.auditList) {
            dispatch({ type: 'HIDE_MESSAGE' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: IAuditListState = {
    auditList: [],
    isLoading: false,
    isArchived: false,
    newAuditId: -1,
    selectedSite: { id: -1, name: '' },
    message: '',
    messageSeverity: undefined,
    isOpen: false,
    isInErrorState: false,
};

export const reducer: Reducer<IAuditListState> = (state: IAuditListState | undefined, incomingAction: Action): IAuditListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUDIT_LIST':
            return {
                auditList: state.auditList,
                newAuditId: state.newAuditId,
                isLoading: true,
                isArchived: state.isArchived,
                selectedSite: action.site,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: state.isInErrorState,
            };
        case 'RECEIVE_AUDIT_LIST':
            return {
                auditList: action.auditList,
                newAuditId: state.newAuditId,
                isLoading: false,
                isArchived: action.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: false,
            };
        case 'RECEIVE_AUDIT_LIST_ERROR':
            return {
                auditList: state.auditList,
                newAuditId: state.newAuditId,
                isLoading: false,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: true,
            };
        case 'RECEIVE_ADD_AUDIT':
            return {
                auditList: [...state.auditList, action.addedAudit],
                newAuditId: action.addedAudit.id,
                isLoading: false,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: state.isInErrorState,
            };
        case 'REQUEST_AUDIT_ARCHIVE':
            return {
                auditList: state.auditList,
                newAuditId: state.newAuditId,
                isLoading: true,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: state.isInErrorState
            }
        case 'RECEIVE_AUDIT_ARCHIVE':

            // Removing archived audits
            var filteredAudits = state.auditList;
            action.archivedIds.forEach(function (id) {
                filteredAudits = filteredAudits.filter(function (audit) {
                    return audit.id !== id;
                });
            });

            return {
                auditList: filteredAudits,
                newAuditId: state.newAuditId,
                isLoading: false,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: state.isInErrorState
            }
        case 'REQUEST_AUDIT_UNARCHIVE':
            return {
                auditList: state.auditList,
                newAuditId: state.newAuditId,
                isLoading: true,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: state.isInErrorState
            }
        case 'RECEIVE_AUDIT_UNARCHIVE':
            // Removing unarchived audits
            var filteredAudits = state.auditList;
            action.unarchivedIds.forEach(function (id) {
                filteredAudits = filteredAudits.filter(function (audit) {
                    return audit.id !== id;
                });
            });

            return {
                auditList: filteredAudits,
                newAuditId: state.newAuditId,
                isLoading: false,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: state.message,
                messageSeverity: state.messageSeverity,
                isOpen: state.isOpen,
                isInErrorState: state.isInErrorState,
            }
        case 'SHOW_MESSAGE':
            return {
                auditList: state.auditList,
                newAuditId: state.newAuditId,
                isLoading: state.isLoading,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: action.message,
                messageSeverity: action.messageSeverity,
                isOpen: true,
                isInErrorState: state.isInErrorState,
            }
        case 'HIDE_MESSAGE':
            return {
                auditList: state.auditList,
                newAuditId: state.newAuditId,
                isLoading: state.isLoading,
                isArchived: state.isArchived,
                selectedSite: state.selectedSite,
                message: '',
                messageSeverity: undefined,
                isOpen: false,
                isInErrorState: state.isInErrorState,
            }
    }

    return state;
};
