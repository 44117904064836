import React, { Component } from 'react';
import { Collapse, NavbarToggler } from 'reactstrap';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export interface IUserAuthentication {
    auth: any,
    onSignIn: any,
    onSignOut: any,
}

export interface IUserLoginState {
    toggle: boolean
    open: boolean
}

export class UserLogin extends Component<any, IUserLoginState> {

    private anchorRef: React.RefObject<HTMLButtonElement>;

    constructor(props: any) {
        super(props);

        this.anchorRef = React.createRef<HTMLButtonElement>();

        this.handleNavToggle = this.handleNavToggle.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleListKeyDown = this.handleListKeyDown.bind(this);

        this.state = {
            open: false,
            toggle: false
        };
    }

    handleNavToggle() {
        this.setState({
            toggle: !this.state.toggle
        });
    }

    handleToggle() {
        this.setState({
            open: !this.state.open
        });
    };
    
    handleClose(event: React.MouseEvent<EventTarget>) {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        this.setState({
            open: false
        });
    };
    
    handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();

            this.setState({
                open: false
            });
        }
    }

    render() {
        if(this.props.account) {
            return (
                <React.Fragment>
                    <NavbarToggler onClick={this.handleNavToggle} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.toggle} navbar>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Button ref={this.anchorRef}
                                        aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleToggle}
                                        endIcon={<Icon>arrow_drop_down</Icon>}>
                                    <span>
                                        <div style={{textTransform: "none"}}>{this.props.account.name}</div>
                                        {this.props.graphProfile ? (<div style={{fontSize: "0.65rem", float: "right"}}>{this.props.graphProfile.officeLocation}</div>) : (<div>No location</div>)
                                        }
                                    </span>
                                </Button>
                                <Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition>
                                    {({ TransitionProps, placement }) => (
                                        <Grow {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                                    <MenuItem onClick={this.props.onSignOut}>         
                                                        <ListItemIcon>
                                                            <ExitToAppIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography variant="inherit" noWrap>
                                                            Logout
                                                        </Typography>
                                                    </MenuItem>
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </div>
                    </Collapse>
                </React.Fragment>
            );
        } else { 
            return (
                <></>
            );
        }
    }
}