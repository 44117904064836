import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import AuditRequests from './components/AuditRequests';
import './custom.css'
import { userContext } from './userContext';
import AuthProvider from './auth/AuthProvider';


const appVersion = '1.1.1';

class App extends React.PureComponent<any> {

    render() {
        return (
            <userContext.Provider value={this.props.account}>
                <Layout {...this.props}>
                    <Route exact path='/'
                        render={(props) => (
                            <Home {...props} {...this.props} />
                        )} />
                    <Route exact path='/audit-requests/:auditId?'
                        render={(props) => (
                            <AuditRequests {...props} {...this.props} />
                        )} />
                    <span style={{
                        fontSize: '11px',
                        marginTop: '5px',
                        color: 'rgba(0, 0, 0, 0.6)'
                    }}>
                        session expires: {this.props.account ? (new Date(this.props.account.idToken.exp * 1000).toLocaleString()) : ("")}
                    </span>
                    <span style={{
                        fontSize: '11px',
                        marginTop: '5px',
                        float: 'right',
                        color: 'rgba(0, 0, 0, 0.6)'
                    }}>
                        v{appVersion}
                    </span>
                </Layout>
            </userContext.Provider>
        );
    }
}

export default AuthProvider(App);