export interface IAudit {
    id: number
    siteId: number;
    creationDate?: string;
    createdBy: string;
    modifiedTimestamp?: string;
    modifiedBy: string;
    description: string;
    numberOfRequests: number;
    auditDateStart?: string;
    auditDateEnd?: string;
    auditor1: string;
    auditor2: string;
    auditor3: string;
    auditLead: string;
    auditLeadEmail: string;
    auditSupport1: string;
    auditSupport1Email: string;
    auditSupport2: string;
    auditSupport2Email: string;
    auditSupport3: string;
    auditSupport3Email: string;
    auditWarRoom1: string;
    auditWarRoom1Email: string;
    auditWarRoom2: string;
    auditWarRoom2Email: string;
    auditWarRoom3: string;
    auditWarRoom3Email: string;
    auditWarRoom4: string;
    auditWarRoom4Email: string;
    archived: boolean;
}

export enum AuditDocumentType {
    Pdf,
    Spreadsheet
}